// 检测是否是全中文
function isChn(str){
  if (str!==''){
    const reg=/^[\u4E00-\u9FA5]+$/;
    if (!reg.test(str)){
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}
export default {
  isChn
};