<template>
  <div class="user-messages">
    <div class="middle">
      <div class="num">修改昵称</div>
      <div class="nums">姓名：</div>
      <el-input placeholder="请输入昵称" class="input" type="text" v-model="nick"></el-input>
      <el-button type="primary" @click="saveNick">保存昵称</el-button>
      <div class="num">修改密码</div>
      <div class="nums">旧密码：</div>
      <el-input placeholder="请输入旧密码（密码大于6为数）" class="input" type="password" v-model="oldPassword"></el-input>
      <div class="nums">新密码：</div>
      <el-input placeholder="请输入新密码（密码大于6为数）" class="input" type="password" v-model="password"></el-input>
      <div class="nums">确认新密码：</div>
      <el-input placeholder="请确认新密码（密码大于6为数）" class="input" type="password" v-model="rePassword"></el-input>
      <el-button type="primary" @click="savePassword">保存密码</el-button>
      <div class="return">
        <el-button type="primary" @click="home">返回首页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {userMessage, updateNickname, userUpdateUserPassword} from '../service/getData';
import chinese from '../utils/chinese';
export default {
  name: 'UserMessage',
  data(){
    return {
      nick: '',
      oldPassword: '',
      password: '',
      rePassword: ''
    };
  },
  mounted(){
    // 获取用户昵称
    userMessage().then(values=>{
      if (values.code === 200){
        this.nick = values.object.nickname;
      } else {
        this.$message({
          type: 'error',
          message: values.message
        });
      }
    });
  },
  methods: {
    home(){
      //返回首页
      this.$router.push({
        name: 'IntroducePage'
      });
    },
    saveNick(){
      //保存昵称
      if (this.nick.trim().length===0){
        this.$message({
          type: 'error',
          message: '姓名不能为空'
        });
      } else if (!chinese.isChn(this.nick)){
        this.$message({
          type: 'error',
          message: '姓名不合法'
        });
      } else {
        updateNickname(this.nick).then(values=>{
          console.log(values);
          if (values.code === 200){
            this.$message({
              type: 'success',
              message: values.message
            });
          } else {
            this.$message({
              type: 'error',
              message: values.message
            });
          }
        });
      }
    },
    savePassword(){
      //保存密码
      if (this.password!==this.rePassword){
        this.$message({
          type: 'error',
          message: '两次密码输入不相同'
        });
      } else if (this.oldPassword.trim().length===0) {
        this.$message({
          type: 'error',
          message: '旧密码不能为空'
        });
      }  else if (this.password.trim().length<=6||this.oldPassword.trim().length<=6){
        this.$message({
          type: 'error',
          message: '密码应大于6位数'
        });
      } else {
        userUpdateUserPassword(this.password, this.oldPassword).then(values=>{
          console.log(values);
          if (values.code===200){
            this.$message({
              type: 'success',
              message: values.message
            });
            this.$store.dispatch('removeMessage', undefined);
            let _this = this;
            setTimeout(() => {
              _this.$router.push({
                name: 'LoginPage'
              });
            }, 1000);
          } else {
            this.$message({
              type: 'error',
              message: values.message
            });
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.user-messages {
  margin-top: 90px;

  @media screen and (max-width: 1300px) {
    margin-top: 0;
  }
  .middle {
    margin: 0 auto;
    width: 300px;
    .input {
      margin-bottom: 15px;
      width: 300px;
    }
    .num {
      margin-bottom: 15px;
      font-weight: 600;
      text-align: center;
    }
    .nums {
      font-size: 14px;
    }
    .return {
      display: flex;
      justify-content: center;
      margin-top: 15px;
    }
  }
}
</style>